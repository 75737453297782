import React from "react";
import { toast } from "sonner";
import axios from "axios";
import {
  ButtonIconLeft,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui";
import { useMutation } from "@tanstack/react-query";

const DatatableExport = (props: {
  export: string;
  exportParams?: Record<string, string>;
  columns: string[];
}) => {
  const { mutate, isPending } = useMutation({
    mutationFn: async () => {
      toast.loading("Exporting data...", { id: "export-toast" });

      const loadingTimeout = setTimeout(() => {
        toast.loading(
          "Exporting data. Depending on the size, this may take a while",
          { id: "export-toast" },
        );
      }, 15000);

      try {
        const response = await axios.post(
          route("table.export", {
            table: props.export,
          }),
          {
            ...route().params,
            ...props.exportParams,
            columns: props.columns,
          },
          {
            responseType: "blob",
          },
        );

        const contentDisposition = response.headers["content-disposition"];
        const filename = contentDisposition
          .split("filename=")[1]
          .split(";")[0]
          .replace(/['"]/g, "");

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.remove();

        window.URL.revokeObjectURL(url);

        toast.success("Export complete", { id: "export-toast" });
      } finally {
        clearTimeout(loadingTimeout);
      }
    },
    onError: async (error) => {
      toast.error(
        (axios.isAxiosError(error) &&
          JSON.parse(await error.response?.data.text()).message) ||
          "Error while exporting data. Please try again.",
        { id: "export-toast" },
      );
    },
  });

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <ButtonIconLeft
          icon={!isPending ? "fa-download" : "fa-refresh fa-spin"}
          variant="secondary"
          disabled={isPending}
          onClick={() => mutate()}
        >
          Export
        </ButtonIconLeft>
      </TooltipTrigger>
      <TooltipContent>
        <p>
          {isPending
            ? "Your export is currently being processed. Please wait for the download to begin."
            : "Export a CSV of current view. Search, filters & column sorting can all be used to organise data for export."}
        </p>
      </TooltipContent>
    </Tooltip>
  );
};

export { DatatableExport };
