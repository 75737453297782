import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "sonner";

import { defaultErrorMessage } from "@/lib/constants";
import { FormMarkupResource, Resource } from "@/types";
import { Member, Role } from "@/types/user-types";
import { LoadingSpinner } from "./LoadingSpinner";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from "./ui";
import { DynamicForm } from "@/components/DynamicForm";
import { LocalFile } from "@/components/FormMultiUploadInput";
import { transformValuesToDynamicFormMarkup } from "@/lib/utils";

export const MemberForm = (props: {
  resource: FormMarkupResource<Member>;
  isProfileUpdate: boolean;
  onDone?: () => void;
}) => {
  const { resource } = props;

  const queryClient = useQueryClient();

  const onSubmit = async (values: Member) => {
    const contractor = values.contractor;
    if (contractor) {
      const signature = contractor.signature as LocalFile | string | null;

      if (
        signature &&
        !(typeof signature === "string") &&
        signature.status === "UPLOADING"
      ) {
        toast.error("Signature upload not completed yet");

        return;
      }

      contractor.signature = signature
        ? typeof signature === "string"
          ? signature
          : signature.url
        : null;
    }

    const response = await axios
      .put<
        Resource<Member>
      >(route("api.settings.members.update", resource.data), values)
      .then((res) => res.data.data);

    queryClient.setQueryData(["profile"], () => response);
    toast.success(
      `${props.isProfileUpdate ? "Profile" : "Member"} updated successfully`,
    );
    props.onDone?.();
  };

  const initialValues = transformValuesToDynamicFormMarkup(
    resource.form_markup,
    resource.data,
  ) as Member;

  return (
    <DynamicForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      formInfo={resource.form_markup}
    />
  );
};

export const MemberSheet = (props: {
  userId: number;
  isProfileUpdate?: boolean;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onDone: () => void;
}) => {
  const { userId, isProfileUpdate = false, onDone, ...otherProps } = props;
  const { data, isLoading, isSuccess } = useQuery({
    enabled: props.open,
    queryKey: ["members", userId],
    queryFn: async () => {
      const response = await axios.get<FormMarkupResource<Member>>(
        route("api.settings.members.show", {
          member: userId,
          form_markup: true,
        }),
      );

      return response.data;
    },
  });

  const rolesQuery = useQuery({
    enabled: props.open,
    queryKey: ["roles"],
    queryFn: async () => {
      const response = await axios.get<Resource<Role[]>>(
        route("api.settings.members.roles"),
      );

      return response.data.data;
    },
  });

  const renderForm = () => {
    if (isLoading || rolesQuery.isLoading) {
      return <LoadingSpinner />;
    }

    if (!isSuccess || !rolesQuery.isSuccess) {
      return (
        <div className="text-center mt-8 text-red-500">
          {defaultErrorMessage}
        </div>
      );
    }

    return (
      <MemberForm
        resource={data}
        isProfileUpdate={isProfileUpdate}
        onDone={onDone}
      />
    );
  };

  return (
    <>
      <Sheet {...otherProps}>
        <SheetContent className="sm:w-1/3">
          <SheetHeader>
            <SheetTitle>Edit user</SheetTitle>
            <SheetDescription>
              {data && `${data.data.first_name} ${data.data.last_name}`}
            </SheetDescription>
          </SheetHeader>
          <div className="mt-8">{renderForm()}</div>
        </SheetContent>
      </Sheet>
    </>
  );
};
