//#region
import { router } from "@inertiajs/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Column,
  ColumnDef,
  ColumnOrderState,
  flexRender,
  functionalUpdate,
  getCoreRowModel,
  Row,
  RowSelectionState,
  SortingState,
  Updater,
  useReactTable,
  VisibilityState,
} from "@tanstack/react-table";
import axios from "axios";
import pluralize from "pluralize";
import { Fragment, ReactNode, useEffect, useMemo, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import { Icon } from "@/components/Icon";
import { DataTablePagination } from "@/components/Pagination";
import {
  Button,
  ButtonIcon,
  ButtonIconLeft,
  Checkbox,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
  DropdownButtonIconLeft,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  IconButton,
  Input,
  Label,
  Popover,
  PopoverContent,
  PopoverTrigger,
  RowDragHandleCell,
  ScrollArea,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  SortableTableRow,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableHeadRow,
  TableRow,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui";
import { usePageData } from "@/hooks/usePageData";
import { generateColumnDefs } from "@/lib/generate-column-defs";
import { useTableServiceContext } from "@/providers/TableServiceProvider";
import {
  PaginatedResource,
  PaginatedResourceDisplayFlag,
  PaginatedResourceFilter,
  PaginatedResourceFilterOption,
  TODO,
} from "@/types";

import { LoadingSpinner } from "./LoadingSpinner";
import { Switch } from "./ui/switch";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { toast } from "sonner";
import { DatatableExport } from "@/components/DatatableExport";
import { SortableDisplayMenuCheckboxItem } from "./SortableDropdownMenu";
import { featureFlags, localStorageKeys } from "@/lib/constants";
import { cn } from "@/lib/utils";
import { useFeatureFlagEnabled } from "posthog-js/react";

//#endregion

const FALLBACK_PAGE_LIMIT = 50;

type DataTableProps<TData, TValue> = {
  tableName:
    | "assetTable"
    | "auditTable"
    | "documentTable"
    | "recordTable"
    | "sampleTable"
    | "suggestedAuditTable"
    | "asset_assetTable"
    | "asset_auditTable"
    | "asset_documentTable"
    | "asset_recordTable"
    | "asset_sampleTable"
    | "audit_notesTable"
    | "audit_recordTable"
    | "audit_sampleTable";
  routeName: string;
  routeParams?: Record<string, string>;
  extraFilters?: Record<string, unknown>[];
  actionColumns?: ColumnDef<TData, TValue>[];
  prefixColumns?: ColumnDef<TData, TValue>[];
  extraActions?: ReactNode;
  syncWithURL?: boolean;
  refetchInterval?: number;
  onRowClick?: (row: Row<TData>) => void;
  getRowId?: (originalRow: TData, index: number, parent?: Row<TData>) => string;
  export?:
    | "assetTable"
    | "auditTable"
    | "documentTable"
    | "recordTable"
    | "sampleTable"
    | "suggestedAuditTable";
  exportParams?: Record<string, string>;
  dndSort?: {
    sortKey: keyof TData;
    onChange: (data: TData[]) => Promise<void>;
  };
  selection?: {
    enabled: boolean;
    isRowEnabled: (row: TData) => boolean;
    tooltip?: string | ((row: TData) => string);
    prefill?: string[];
    bulkActions: (
      rowSelectionState: RowSelectionState,
      onDone: () => void,
    ) => ReactNode;
  };
};

export type SelectedFilter = {
  key: string;
  values: string[];
  condition: "equals" | "not_equals";
};

type DataTableFiltersProps = {
  allFilters: PaginatedResourceFilter[];
  filters: SelectedFilter[];
  onFilterChange: (selection: SelectedFilter[]) => void;
};

const filterColumnCallback = <T = unknown,>(column: Column<T>) =>
  !column.id.startsWith("action") &&
  column.id !== "actions" &&
  column.id !== "drag-handle" &&
  column.id !== "select";

export const DataTable = <TData extends object, TValue>(
  props: DataTableProps<TData, TValue>,
) => {
  const {
    platform: { translations },
  } = usePageData();
  const queryClient = useQueryClient();
  const { setPreviousTableRouteObject, setPreviousTableData } =
    useTableServiceContext();
  const [params, setParams] = useState(() => {
    if (!props.syncWithURL) {
      return {
        search: "",
        order_by: "",
        order_direction: "",
        limit: FALLBACK_PAGE_LIMIT,
        filters: [],
        page: 1,
        displayFlags: {} as Record<string, "true" | "false" | "">,
      };
    }
    const urlParams = route().params;
    console.log({ urlParams });
    const {
      search,
      order_by,
      order_direction,
      limit,
      filters,
      page,
      ...displayFlags
    } = urlParams;
    return {
      search: String(search || ""),
      order_by: String(order_by || ""),
      order_direction: String(order_direction || ""),
      limit: Number(limit || FALLBACK_PAGE_LIMIT),
      filters: filters
        ? (JSON.parse(atob(decodeURIComponent(filters as string))) as [])
        : [],
      page: Number(page || 1),
      displayFlags: displayFlags as Record<string, "true" | "false" | "">,
    };
  });

  const { data: tableData, isLoading } = useQuery({
    queryKey: [props.routeName, params, props.routeParams, props.extraFilters],
    queryFn: async () => {
      const { displayFlags, ...rest } = params;

      const routeName = props.routeName;
      const routeParams = {
        ...rest,
        ...props.routeParams,
        filters: btoa(
          JSON.stringify([...params.filters, ...(props.extraFilters || [])]),
        ),
        ...displayFlags,
      };

      const routeUrl = route(routeName, routeParams);

      setPreviousTableRouteObject({
        routeName,
        routeParams,
      });

      const response = await axios.get<PaginatedResource<TData>>(routeUrl);

      try {
        const indexedData = response.data.data.map((item: TODO) => {
          return {
            totalCount: response.data.meta.total,
            objectId: item.id as number,
            routeKey: item.route_key as string,
            currentPage: response.data.meta.current_page,
            perPage: response.data.meta.per_page,
          };
        });

        setPreviousTableData(indexedData);
      } catch (error) {
        console.error(error);
      }

      return response.data;
    },
    placeholderData: (prev) => prev,
    refetchInterval: props.refetchInterval,
  });

  console.log(tableData?.pageLimits);

  const dndSortColumns = useMemo(() => {
    if (
      !props.dndSort ||
      (params.order_by && params.order_by !== "") ||
      (params.order_direction && params.order_direction !== "")
    ) {
      return [];
    }

    return [
      {
        id: "drag-handle",
        header: "",
        cell: ({ row }: { row: Row<TData> }) => (
          <RowDragHandleCell rowId={row.id} />
        ),
        size: 60,
      },
    ];
  }, [props.dndSort, params.order_by, params.order_direction]);

  const reloadPage = (newParams: typeof params) => {
    const { displayFlags, ...rest } = newParams;
    router.get(
      route(route().current() as string, {
        ...route().params,
        ...rest,
        limit: String(newParams.limit),
        page: String(newParams.page),
        filters: btoa(JSON.stringify([...newParams.filters])),
        ...displayFlags,
      }),
      {},
      {
        preserveState: true,
        replace: true,
      },
    );
  };

  const sorting: SortingState = [
    { id: params.order_by, desc: params.order_direction !== "asc" },
  ];

  const setSorting = (sortUpdater: Updater<SortingState>) => {
    const newSorting = functionalUpdate(sortUpdater, sorting);
    if (!newSorting[0]) {
      return;
    }
    const new_order_by = newSorting[0].id;
    const new_order_direction = newSorting[0].desc ? "desc" : "asc";
    if (
      new_order_by === params.order_by &&
      new_order_direction === params.order_direction
    ) {
      return;
    }
    const newParams = {
      ...params,
      order_by: new_order_by,
      order_direction: new_order_direction,
    };
    setParams(newParams);
    if (props.syncWithURL) {
      reloadPage(newParams);
    }
  };

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
    getSettingsFromStorage({
      tableName: props.tableName,
      settingsName: "columnVisibility",
    }),
  );

  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(
    getSettingsFromStorage({
      tableName: props.tableName,
      settingsName: "columnOrder",
    }),
  );

  const updateColumnVisibility = (
    visibilityUpdater: Updater<VisibilityState>,
  ) => {
    const newColumnVisibility = functionalUpdate(
      visibilityUpdater,
      columnVisibility,
    );
    setColumnVisibility(newColumnVisibility);
    setSettingsInStorage({
      tableName: props.tableName,
      settings: {
        name: "columnVisibility",
        settings: newColumnVisibility,
      },
    });
  };

  const updateColumnOrder = (orderUpdater: Updater<ColumnOrderState>) => {
    const newColumnOrder = functionalUpdate(orderUpdater, columnOrder);
    setColumnOrder(newColumnOrder);
    setSettingsInStorage({
      tableName: props.tableName,
      settings: {
        name: "columnOrder",
        settings: newColumnOrder,
      },
    });
  };

  const isBulkActionsEnabled = useFeatureFlagEnabled(featureFlags.BULK_ACTIONS);

  const data = useMemo(() => {
    return tableData ? tableData.data : ([] as TData[]);
  }, [tableData]);
  const columns = useMemo(() => {
    return tableData
      ? [
          ...(props.selection?.enabled && isBulkActionsEnabled
            ? [
                {
                  id: "select",
                  header: "",
                  cell: ({ row }: { row: Row<TData> }) => {
                    const tooltip =
                      typeof props.selection?.tooltip === "function"
                        ? props.selection.tooltip(row.original)
                        : props.selection?.tooltip;
                    const checked = row.getIsSelected();
                    const enabled = props.selection?.isRowEnabled(row.original);
                    return (
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <div
                            className={cn(
                              "group-hover/row:visible",
                              checked ? "visible" : "invisible",
                            )}
                          >
                            <div
                              className={cn(
                                checked || !enabled
                                  ? ""
                                  : "opacity-50 hover:opacity-100 transition-opacity duration-100",
                              )}
                            >
                              <Checkbox
                                checked={checked}
                                disabled={!enabled}
                                onCheckedChange={(checked) => {
                                  row.toggleSelected(!!checked);
                                }}
                                aria-label="Select row"
                                className={cn(
                                  "flex items-center justify-center",
                                  "relative after:absolute after:inset-[-50%] after:content-[''] after:size-[300%]",
                                )}
                              />
                            </div>
                          </div>
                        </TooltipTrigger>
                        {tooltip && (
                          <TooltipContent side="right">
                            {tooltip}
                          </TooltipContent>
                        )}
                      </Tooltip>
                    );
                  },
                },
              ]
            : []),
          ...dndSortColumns,
          ...(props.prefixColumns || []),
          ...generateColumnDefs<TData>(tableData.columns),
          ...(props.actionColumns || []),
        ]
      : [];
  }, [
    dndSortColumns,
    props.actionColumns,
    props.prefixColumns,
    props.selection,
    tableData,
    isBulkActionsEnabled,
  ]);

  const table = useReactTable({
    data,
    columns,
    enableRowSelection: props.selection?.enabled,
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
    onSortingChange: setSorting,
    state: {
      sorting,
      columnVisibility,
      columnOrder,
    },
    enableSortingRemoval: false,
    getRowId: props.getRowId,
    onColumnVisibilityChange: updateColumnVisibility,
  });

  useEffect(() => {
    if (props.selection?.enabled && isBulkActionsEnabled) {
      const onKeyPress = (e: KeyboardEvent) => {
        if (e.key === "Escape") {
          table.setRowSelection({});
        }
      };
      window.addEventListener("keydown", onKeyPress);
      return () => {
        window.removeEventListener("keydown", onKeyPress);
      };
    }
  }, [props.selection?.enabled, table, isBulkActionsEnabled]);

  useEffect(() => {
    if (
      props.selection?.enabled &&
      props.selection?.prefill &&
      props.selection.prefill.length > 0 &&
      Object.keys(table.getState().rowSelection).length === 0
    ) {
      table.setRowSelection(
        props.selection.prefill.reduce((p, c) => ({ ...p, [c]: true }), {}),
      );
    }
  }, [table, props.selection?.enabled, props.selection?.prefill]);

  const updateParam = (key: string, value: unknown) => {
    const newParams = {
      ...params,
      [key]: value,
    };
    setParams(newParams);
    if (props.syncWithURL) {
      reloadPage(newParams);
    }
  };

  const onSearch = (query: string) => updateParam("search", query);
  const debouncedHandleSearch = useDebouncedCallback(onSearch, 500);

  const tableColumnsForDisplayDropdown = table
    .getAllLeafColumns()
    .filter(filterColumnCallback);

  const hasHiddenColumns = tableColumnsForDisplayDropdown.some(
    (column) => !column.getIsVisible(),
  );

  const resetColumnVisibility = () => {
    setColumnVisibility(
      tableColumnsForDisplayDropdown.reduce(
        (acc, column) => ({
          ...acc,
          [column.id]: true,
        }),
        {} as VisibilityState,
      ),
    );
    const tableSettings = localStorage.getItem(localStorageKeys.TABLE_SETTING);
    if (tableSettings) {
      const parsedTableSettings = JSON.parse(tableSettings);
      if (parsedTableSettings[props.tableName]) {
        delete parsedTableSettings[props.tableName].columnVisibility;
      }
      localStorage.setItem(
        localStorageKeys.TABLE_SETTING,
        JSON.stringify(parsedTableSettings),
      );
    }
  };

  useEffect(() => {
    if (!columnVisibility && tableColumnsForDisplayDropdown.length > 0) {
      setColumnVisibility(
        tableColumnsForDisplayDropdown.reduce(
          (acc, column) => ({
            ...acc,
            [column.id]: true,
          }),
          {} as VisibilityState,
        ),
      );
    }
  }, [tableColumnsForDisplayDropdown, columnVisibility]);

  const orderableColumns = table
    .getAllLeafColumns()
    .filter((column) => filterColumnCallback(column) && column.getCanSort());

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {}),
  );

  const tableRows = table.getRowModel().rows;

  const [rows, setRows] = useState<Row<TData>[] | null>(tableRows);

  const dataIds = rows?.map((item) => item.id);

  const queryKey = [
    props.routeName,
    params,
    props.routeParams,
    props.extraFilters,
  ];

  const { mutate } = useMutation({
    mutationFn: async (data: TData[]) => props.dndSort?.onChange(data),
    onMutate: async (newData: TData[]) => {
      // Cancel any outgoing refetch
      // (so they don't overwrite our optimistic update)

      await queryClient.cancelQueries({ queryKey });

      // Snapshot the previous value
      const prevData = queryClient.getQueryData(queryKey);

      // Optimistically update to the new value
      queryClient.setQueryData(
        queryKey,
        (oldData: PaginatedResource<TData>) => ({
          ...oldData,
          data: newData,
        }),
      );

      // Return a context object with the snapshot value
      return { prevData };
    },
    onError: (_err, _newTodo, context) => {
      toast.error("Unable to update sequence. Your changes have been reverted");
      queryClient.setQueryData(queryKey, context?.prevData);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  const onDragEnd = async (e: DragEndEvent) => {
    if (!props.dndSort) {
      return;
    }

    const { active, over } = e;
    if (active && over && active.id !== over.id && rows && dataIds) {
      const activeIndex = dataIds.indexOf(active.id as string);
      const overIndex = dataIds.indexOf(over.id as string);

      // If active or over id is not found, return early
      if (activeIndex === -1 || overIndex === -1) {
        return;
      }

      const updatedRows = arrayMove(rows, activeIndex, overIndex);

      setRows([...updatedRows]);

      // Find the range of indices affected by the move
      const startIndex = Math.min(activeIndex, overIndex);
      const endIndex = Math.max(activeIndex, overIndex);

      const fallbackValue = (params.page - 1) * params.limit;

      let startIndexSequence = Number(
        updatedRows[startIndex - 1]?.original[props.dndSort.sortKey] ||
          fallbackValue,
      );

      // Recalculate sequence numbers only for rows within this range
      for (let i = startIndex; i <= endIndex; i++) {
        // Ensure to increment the sequence relative to its order in the table
        updatedRows[i].original[props.dndSort.sortKey] = String(
          startIndexSequence + 1,
        ) as TData[keyof TData];
        startIndexSequence++;
      }

      // Prepare the data to be passed to the onChange callback
      const updatedData = updatedRows.map((row) => row.original);

      mutate(updatedData);
    }
  };

  useEffect(() => {
    if (tableRows) {
      setRows(tableRows);
    }
  }, [tableRows]);

  if (isLoading || !tableData) {
    return <LoadingSpinner />;
  }

  const orderBy = params.order_by || tableData.defaultOrderBy;

  return (
    <div className="flex-1 overflow-hidden">
      <div className="h-full flex flex-col">
        <div className="p-4">
          <div className="flex flex-row justify-between">
            <div>
              <DataTableFilters
                allFilters={tableData.filters}
                filters={params.filters}
                onFilterChange={(filters) => updateParam("filters", filters)}
              />
            </div>
            <div className="flex flex-shrink-0 space-x-2">
              <div className="inline-flex">{props.extraActions}</div>
              {props.export && (
                <DatatableExport
                  export={props.export}
                  exportParams={props.exportParams}
                  columns={tableColumnsForDisplayDropdown
                    .filter((column) => column.getIsVisible())
                    .map((column) => column.columnDef.header as string)}
                />
              )}
              <div className="inline-flex">
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <DropdownButtonIconLeft
                      icon={"fa-sliders"}
                      variant="secondary"
                    >
                      {translations.display}
                    </DropdownButtonIconLeft>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end" className="w-72">
                    <DropdownMenuLabel className="space-x-2 flex flex-row items-center justify-between">
                      <Label className={"w-16 shrink-0"}>
                        {translations.order_by}
                      </Label>
                      <div className="flex-1 inline-flex items-center gap-2">
                        <Select
                          defaultValue={orderBy}
                          onValueChange={(column) =>
                            updateParam("order_by", column)
                          }
                          disabled={orderableColumns.length === 0}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="None" />
                          </SelectTrigger>
                          <SelectContent>
                            {orderableColumns.map((column) => {
                              return (
                                <SelectItem key={column.id} value={column.id}>
                                  {column.id}
                                </SelectItem>
                              );
                            })}
                          </SelectContent>
                        </Select>
                        {orderBy && (
                          <IconButton
                            className={"shrink-0"}
                            variant={"tertiary"}
                            icon={
                              params.order_direction === "asc" ||
                              !params.order_direction
                                ? "fa-arrow-down-short-wide"
                                : "fa-arrow-down-wide-short"
                            }
                            onClick={() => {
                              updateParam(
                                "order_direction",
                                params.order_direction === "asc" ||
                                  !params.order_direction
                                  ? "desc"
                                  : "asc",
                              );
                            }}
                          />
                        )}
                      </div>
                    </DropdownMenuLabel>
                    <DropdownMenuLabel className="space-x-2 flex flex-row items-center justify-between">
                      <Label className={"w-16 shrink-0"}>
                        {translations.per_page}
                      </Label>
                      <div className="flex-1 inline-flex">
                        <Select
                          defaultValue={String(params.limit)}
                          onValueChange={(value) => updateParam("limit", value)}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Select" />
                          </SelectTrigger>
                          <SelectContent>
                            {tableData?.pageLimits.map((limit) => (
                              <SelectItem key={limit} value={String(limit)}>
                                {limit}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </div>
                    </DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    {tableData.displayFlags?.length && (
                      <>
                        <DataTableDisplayFlagsToggle
                          displayFlags={params.displayFlags}
                          allDisplayFlags={tableData.displayFlags}
                          onDisplayFlagToggle={(key, value) =>
                            updateParam("displayFlags", {
                              ...params.displayFlags,
                              [key]: String(value),
                            })
                          }
                        />
                        <DropdownMenuSeparator />
                      </>
                    )}
                    <ColumnVisibility
                      tableColumnsForDisplayDropdown={table.getAllLeafColumns()}
                      hasHiddenColumns={hasHiddenColumns}
                      resetColumnVisibility={resetColumnVisibility}
                      updateColumnOrder={updateColumnOrder}
                    />
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
              <div className="inline-flex">
                <Input
                  className="h-8"
                  type="search"
                  placeholder={translations.search}
                  defaultValue={params.search}
                  onChange={(e) => {
                    debouncedHandleSearch(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-col rounded-md overflow-hidden">
          <DndContext
            collisionDetection={closestCenter}
            modifiers={[restrictToVerticalAxis]}
            onDragEnd={onDragEnd}
            sensors={sensors}
          >
            <Table>
              <TableHeader>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableHeadRow key={headerGroup.id} className="group/row">
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableHead
                          key={header.id}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {header.isPlaceholder ? null : (
                            <span className="flex flex-row items-center">
                              {flexRender(
                                header.id === "select" &&
                                  isBulkActionsEnabled ? (
                                  <span className="pr-2">
                                    <div
                                      className={cn(
                                        "group-hover/row:visible",
                                        table.getIsAllPageRowsSelected() ||
                                          table.getIsSomePageRowsSelected()
                                          ? "visible"
                                          : "invisible",
                                      )}
                                    >
                                      <div
                                        className={cn(
                                          table.getIsAllPageRowsSelected() ||
                                            table.getIsSomePageRowsSelected()
                                            ? ""
                                            : "opacity-50 hover:opacity-100 transition-opacity duration-100",
                                        )}
                                      >
                                        <Checkbox
                                          checked={
                                            table.getIsAllPageRowsSelected()
                                              ? true
                                              : table.getIsSomePageRowsSelected()
                                                ? "indeterminate"
                                                : false
                                          }
                                          onCheckedChange={() => {
                                            if (
                                              table.getIsSomePageRowsSelected()
                                            ) {
                                              table.toggleAllPageRowsSelected(
                                                false,
                                              );
                                              return;
                                            }
                                            table
                                              .getRowModel()
                                              .rows.forEach((row) => {
                                                if (
                                                  props.selection?.isRowEnabled(
                                                    row.original,
                                                  )
                                                ) {
                                                  row.toggleSelected();
                                                }
                                              });
                                          }}
                                          aria-label="Select all"
                                          className={cn(
                                            "flex items-center justify-center",
                                            "relative after:absolute after:inset-[-50%] after:content-[''] after:size-[300%]",
                                          )}
                                        />
                                      </div>
                                    </div>
                                  </span>
                                ) : (
                                  header.column.columnDef.header
                                ),
                                header.getContext(),
                              )}
                              {{
                                asc: (
                                  <Icon
                                    icon="fa-angle-up"
                                    className="ml-1 text-xs"
                                  />
                                ),
                                desc: (
                                  <Icon
                                    icon="fa-angle-down"
                                    className="ml-1 text-xs"
                                  />
                                ),
                              }[header.column.getIsSorted() as string] ?? null}
                              {!header.column.getIsSorted() &&
                                header.column.getCanSort() && (
                                  <Icon
                                    icon="fa-angles-up-down"
                                    className="ml-1 text-xs"
                                  />
                                )}
                            </span>
                          )}
                        </TableHead>
                      );
                    })}
                  </TableHeadRow>
                ))}
              </TableHeader>
              <TableBody>
                {rows?.length ? (
                  <SortableContext
                    items={dataIds as UniqueIdentifier[]}
                    strategy={verticalListSortingStrategy}
                  >
                    {rows.map((row) => (
                      <SortableTableRow
                        key={row.id}
                        rowKey={row.id}
                        data-state={row.getIsSelected() && "selected"}
                        className={
                          props.onRowClick
                            ? "group/row cursor-pointer"
                            : "group/row"
                        }
                        onClick={(e) => {
                          if (table.getSelectedRowModel().rows.length) {
                            const enabled = props.selection?.isRowEnabled(
                              row.original,
                            );
                            if (enabled) {
                              row.toggleSelected();
                            }
                            return;
                          }
                          if (
                            props.onRowClick &&
                            e.nativeEvent.target instanceof HTMLTableCellElement
                          ) {
                            props.onRowClick(row);
                          }
                        }}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <TableCell key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </TableCell>
                        ))}
                      </SortableTableRow>
                    ))}
                  </SortableContext>
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={columns.length}
                      className="h-24 text-center"
                    >
                      No results.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </DndContext>
        </div>
        {
          <DataTablePagination
            {...tableData.meta}
            onLinkClick={(link) => {
              updateParam(
                "page",
                Object.fromEntries(new URL(link.url).searchParams).page,
              );
            }}
          />
        }
        {isBulkActionsEnabled && (
          <div
            className={cn(
              "fixed -bottom-40 left-0 right-0 flex justify-center",
              (props.selection?.prefill &&
                props.selection.prefill.length > 0) ||
                Object.keys(table.getState().rowSelection).length
                ? "bottom-16 animate-in slide-in-from-bottom-40 duration-500 ease-in-out"
                : "-bottom-40 animate-out slide-out-to-bottom-40 duration-500 ease-in-out",
            )}
          >
            <div className="flex items-center space-x-2 rounded-lg border bg-background p-2 shadow-lg shadow-primary/30 transition-shadow duration-300">
              <div className="pl-2 flex flex-row items-center space-x-1 border border-dashed border-foreground/30 rounded-lg divide-x">
                <span className="text-sm">
                  {Object.keys(table.getState().rowSelection).length} selected
                </span>
                <span>
                  <IconButton
                    variant="tertiary"
                    size="icon"
                    icon="fa-xmark"
                    onClick={() => {
                      table.setRowSelection({});
                    }}
                  />
                </span>
              </div>
              {props.selection?.bulkActions(
                table.getState().rowSelection,
                () => {
                  table.setRowSelection({});
                },
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const ColumnVisibility = <TData extends object>(props: {
  tableColumnsForDisplayDropdown: Column<TData, unknown>[];
  resetColumnVisibility: () => void;
  hasHiddenColumns: boolean;
  updateColumnOrder: (orderUpdater: Updater<ColumnOrderState>) => void;
}) => {
  const tableColumnsForDisplayDropdown =
    props.tableColumnsForDisplayDropdown.filter(filterColumnCallback);

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {}),
  );

  const columnIds = tableColumnsForDisplayDropdown.map((column) => column.id);

  const onDragEnd = (event: DragEndEvent) => {
    console.info(event);
    const { delta, active, over } = event;
    if (active && over) {
      if (active.id === over.id && delta.y === 0) {
        const column = tableColumnsForDisplayDropdown.find(
          (c) => c.id === active.id,
        );
        if (column) {
          column.toggleVisibility(!column.getIsVisible());
        }
        return;
      }
      if (active.id !== over.id && columnIds) {
        const activeIndex = columnIds.indexOf(active.id as string);
        const overIndex = columnIds.indexOf(over.id as string);

        // If active or over id is not found, return early
        if (activeIndex === -1 || overIndex === -1) {
          return;
        }

        const updatedColumns = arrayMove(columnIds, activeIndex, overIndex);
        props.tableColumnsForDisplayDropdown.forEach((column, index) => {
          if (filterColumnCallback(column)) {
            return null;
          }

          updatedColumns.splice(index, 0, column.id);
        });

        props.updateColumnOrder(() => {
          return updatedColumns;
        });
      }
    }
  };

  return (
    <>
      <div className="w-full flex flex-row items-center justify-between">
        <Label className="text-sm font-medium px-1">Column</Label>
        <Button
          variant={"tertiary"}
          onClick={props.resetColumnVisibility}
          className={!props.hasHiddenColumns ? "opacity-0 cursor-default" : ""}
        >
          Reset
        </Button>
      </div>
      <ScrollArea
        className={`${tableColumnsForDisplayDropdown.length > 8 ? "h-72" : ""} px-1`}
      >
        <DndContext
          collisionDetection={closestCenter}
          modifiers={[restrictToVerticalAxis]}
          onDragEnd={onDragEnd}
          sensors={sensors}
        >
          <SortableContext
            items={columnIds}
            strategy={verticalListSortingStrategy}
          >
            {tableColumnsForDisplayDropdown.map((column) => {
              return (
                <SortableDisplayMenuCheckboxItem
                  rowKey={column.id}
                  key={column.id}
                  checked={column.getIsVisible()}
                  onSelect={(e) => {
                    e.preventDefault();
                  }}
                  onCheckedChange={(checked) =>
                    column.toggleVisibility(checked)
                  }
                >
                  {column.id}
                </SortableDisplayMenuCheckboxItem>
              );
            })}
          </SortableContext>
        </DndContext>
      </ScrollArea>
    </>
  );
};

type DataTableDisplayFlagsToggleProps = {
  displayFlags: Record<string, "true" | "false" | "">;
  allDisplayFlags: PaginatedResourceDisplayFlag[];
  onDisplayFlagToggle: (key: string, value: boolean) => void;
};

const DataTableDisplayFlagsToggle = ({
  displayFlags,
  allDisplayFlags,
  onDisplayFlagToggle,
}: DataTableDisplayFlagsToggleProps) => {
  return (
    <>
      {allDisplayFlags.map((displayFlag) => {
        const selected = displayFlags[displayFlag.key] === "true";
        return (
          <DropdownMenuLabel
            className="flex items-center justify-between"
            key={displayFlag.key}
          >
            <div className="flex items-center">
              <Label>{displayFlag.label}</Label>
              {displayFlag.tooltip && (
                <Tooltip>
                  <TooltipTrigger>
                    <Icon
                      icon="fa-circle-info"
                      className="pl-2 text-body-text-subtle"
                    />
                  </TooltipTrigger>
                  <TooltipContent>{displayFlag.tooltip}</TooltipContent>
                </Tooltip>
              )}
            </div>
            <div className="inline-flex">
              <Switch
                checked={selected}
                onCheckedChange={(checked) =>
                  onDisplayFlagToggle(displayFlag.key, checked)
                }
              />
            </div>
          </DropdownMenuLabel>
        );
      })}
    </>
  );
};

const DataTableFilters = ({
  allFilters,
  filters,
  onFilterChange,
}: DataTableFiltersProps) => {
  console.info({ allFilters, filters });
  const {
    platform: { translations },
  } = usePageData();
  const [addingFilter, setAddingFilter] =
    useState<PaginatedResourceFilter | null>(null);
  const [selectedFilters, setSelectedFilters] =
    useState<SelectedFilter[]>(filters);

  const updateFilters = (selection: SelectedFilter[]) => {
    setSelectedFilters(selection);
    onFilterChange(selection);
  };

  const addFilter = (
    key: PaginatedResourceFilter["key"],
    option: PaginatedResourceFilterOption,
  ) => {
    const keyIndex = selectedFilters.findIndex((s) => s.key === key);
    if (keyIndex < 0) {
      updateFilters([
        ...selectedFilters,
        { key, condition: "equals", values: [option.value] },
      ]);
    } else {
      const prevSelection = selectedFilters[keyIndex];
      const newValues = [...prevSelection.values, option.value];
      updateFilters([
        ...selectedFilters.slice(0, keyIndex),
        { ...prevSelection, values: newValues },
        ...selectedFilters.slice(keyIndex + 1),
      ]);
    }
  };

  const removeFilter = (
    key: PaginatedResourceFilter["key"],
    option: PaginatedResourceFilterOption,
  ) => {
    const keyIndex = selectedFilters.findIndex((s) => s.key === key);
    if (keyIndex < 0) {
      return;
    }
    const prevSelection = selectedFilters[keyIndex];
    const newValues = prevSelection.values.filter((v) => v !== option.value);
    updateFilters([
      ...selectedFilters.slice(0, keyIndex),
      { ...prevSelection, values: newValues },
      ...selectedFilters.slice(keyIndex + 1),
    ]);
  };

  const changeFilter = (
    key: PaginatedResourceFilter["key"],
    option: PaginatedResourceFilterOption,
  ) => {
    const keyIndex = selectedFilters.findIndex((s) => s.key === key);
    if (keyIndex < 0) {
      updateFilters([
        ...selectedFilters,
        { key, condition: "equals", values: [option.value] },
      ]);
    } else {
      const prevSelection = selectedFilters[keyIndex];
      const newValues = [option.value];
      updateFilters([
        ...selectedFilters.slice(0, keyIndex),
        { ...prevSelection, values: newValues },
        ...selectedFilters.slice(keyIndex + 1),
      ]);
    }
  };

  const removeEmptyFilters = () => {
    const nonEmptyFilters = selectedFilters.filter((s) => s.values.length);
    updateFilters(nonEmptyFilters);
  };

  const toggleCondition = (selection: SelectedFilter) => {
    const selectionIndex = selectedFilters.findIndex(
      (s) => s.key === selection.key,
    );
    if (selectionIndex >= 0) {
      const nextCondition =
        selection.condition === "equals" ? "not_equals" : "equals";
      updateFilters([
        ...selectedFilters.slice(0, selectionIndex),
        { ...selection, condition: nextCondition },
        ...selectedFilters.slice(selectionIndex + 1),
      ]);
    }
  };

  const getConditionLabel = (selection: SelectedFilter) => {
    if (selection.condition === "equals") {
      if (selection.values.length < 2) {
        return "is";
      } else {
        return "is any of";
      }
    }
    return "is not";
  };

  const clearSelection = (selection: SelectedFilter) => {
    const selectionIndex = selectedFilters.findIndex(
      (s) => s.key === selection.key,
    );
    if (selectionIndex >= 0) {
      updateFilters([
        ...selectedFilters.slice(0, selectionIndex),
        ...selectedFilters.slice(selectionIndex + 1),
      ]);
    }
  };

  const nonAppliedFilters = allFilters.filter(
    (filter) => !selectedFilters.find((s) => s.key === filter.key),
  );

  return (
    <div className="flex flex-row flex-wrap items-center">
      {selectedFilters.map((selection) => {
        const filter = allFilters.find(
          (f) => f.key === selection.key,
        ) as PaginatedResourceFilter;
        return (
          <div
            key={selection.key}
            className="flex flex-row items-center space-x-0.5 mr-2 mb-2"
          >
            <Button className="rounded-l rounded-r-none">{filter.label}</Button>
            <Button
              onClick={() => {
                toggleCondition(selection);
              }}
              className="rounded-none"
            >
              {getConditionLabel(selection)}
            </Button>
            <FilterModifier
              removeEmptyFilters={removeEmptyFilters}
              selection={selection}
              selectedFilters={selectedFilters}
              removeFilter={removeFilter}
              addFilter={addFilter}
              changeFilter={changeFilter}
              filter={filter}
            />
            <IconButton
              onClick={() => {
                clearSelection(selection);
              }}
              className="rounded-l-none rounded-r"
              variant="primary"
              icon="fa-xmark"
            />
          </div>
        );
      })}
      {(addingFilter || nonAppliedFilters.length > 0) && (
        <Popover
          onOpenChange={(open) => {
            if (!open) {
              setAddingFilter(null);
              removeEmptyFilters();
            }
          }}
        >
          <PopoverTrigger asChild>
            {selectedFilters.length > 0 ? (
              <Button size={"icon"} variant={"tertiary"} className="mb-2">
                <ButtonIcon icon="fa-plus" />
              </Button>
            ) : (
              <ButtonIconLeft
                variant={"secondary"}
                icon="fa-filter"
                className="mb-2"
              >
                {translations.filter}
              </ButtonIconLeft>
            )}
          </PopoverTrigger>
          <PopoverContent align="start" className="p-0">
            {!addingFilter && nonAppliedFilters.length > 0 ? (
              <Command>
                <CommandList>
                  <CommandGroup>
                    {nonAppliedFilters.map((filter) => {
                      return (
                        <CommandItem
                          onSelect={() => setAddingFilter(filter)}
                          key={filter.key}
                        >
                          <span>{filter.label}</span>
                        </CommandItem>
                      );
                    })}
                  </CommandGroup>
                </CommandList>
              </Command>
            ) : (
              <>
                {addingFilter && (
                  <Command>
                    <CommandInput
                      className="border-0 focus:ring-0 focus:outline-none"
                      placeholder={addingFilter.label}
                    />
                    <CommandList>
                      <CommandEmpty>No results found.</CommandEmpty>
                      <CommandGroup>
                        {addingFilter.options.map((option) => (
                          <Fragment key={option.value}>
                            <FilterItem
                              option={option}
                              selectedFilters={selectedFilters}
                              addingFilter={addingFilter}
                              changeFilter={changeFilter}
                              addFilter={addFilter}
                              removeFilter={removeFilter}
                            />
                          </Fragment>
                        ))}
                      </CommandGroup>
                    </CommandList>
                  </Command>
                )}
              </>
            )}
          </PopoverContent>
        </Popover>
      )}
    </div>
  );
};

const FilterItem = (props: {
  option: PaginatedResourceFilterOption;
  selectedFilters: SelectedFilter[];
  addingFilter: PaginatedResourceFilter;
  changeFilter: (key: string, option: PaginatedResourceFilterOption) => void;
  addFilter: (key: string, option: PaginatedResourceFilterOption) => void;
  removeFilter: (key: string, option: PaginatedResourceFilterOption) => void;
}) => {
  const isSelected = props.selectedFilters
    .find((s) => s.key === props.addingFilter.key)
    ?.values.includes(props.option.value);
  return (
    <CommandItem
      className="flex items-center gap-2"
      onSelect={() => {
        if (props.addingFilter.singleCondition) {
          props.changeFilter(props.addingFilter.key, props.option);
          return;
        }
        if (isSelected) {
          props.removeFilter(props.addingFilter.key, props.option);
        } else {
          props.addFilter(props.addingFilter.key, props.option);
        }
      }}
      key={props.option.value}
    >
      <Checkbox
        checked={isSelected}
        className={!isSelected ? "opacity-50" : ""}
      />
      <span>{props.option.label}</span>
    </CommandItem>
  );
};

const FilterModifier = ({
  removeEmptyFilters,
  selection,
  selectedFilters,
  removeFilter,
  addFilter,
  changeFilter,
  filter,
}: {
  removeEmptyFilters: () => void;
  selection: SelectedFilter;
  selectedFilters: SelectedFilter[];
  removeFilter: (key: string, option: PaginatedResourceFilterOption) => void;
  addFilter: (key: string, option: PaginatedResourceFilterOption) => void;
  changeFilter: (key: string, option: PaginatedResourceFilterOption) => void;
  filter: PaginatedResourceFilter;
}) => {
  const getOrderedOptions = () => {
    const selectedOptions = filter.options.filter((option) => {
      const isSelected = selectedFilters
        .find((s) => s.key === filter.key)
        ?.values.includes(option.value);
      return isSelected;
    });
    const otherOptions = filter.options.filter((option) => {
      const isSelected = selectedFilters
        .find((s) => s.key === filter.key)
        ?.values.includes(option.value);
      return !isSelected;
    });
    return [...selectedOptions, "line-break", ...otherOptions];
  };

  const [orderedOptions, setOrderedOptions] = useState(getOrderedOptions());

  const getSelectionLabel = (
    selection: SelectedFilter,
    filter: PaginatedResourceFilter,
  ) => {
    if (selection.values.length === 1) {
      const selectedOption = filter.options.find(
        (o) => o.value === selection.values[0],
      );
      return selectedOption?.label;
    }
    return pluralize(filter.label.toLowerCase(), selection.values.length, true);
  };

  return (
    <Popover
      onOpenChange={(open) => {
        if (!open) {
          removeEmptyFilters();
        } else {
          setOrderedOptions(getOrderedOptions());
        }
      }}
    >
      <PopoverTrigger asChild>
        <Button className="rounded-none">
          {getSelectionLabel(selection, filter)}
        </Button>
      </PopoverTrigger>
      <PopoverContent align="start" className="p-0">
        <Command>
          <CommandInput
            className="border-0 focus:ring-0 focus:outline-none"
            placeholder={filter.label}
          />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {orderedOptions.map((option) => {
                const key = typeof option === "string" ? option : option.value;
                return (
                  <Fragment key={key}>
                    {typeof option === "string" ? (
                      <CommandSeparator />
                    ) : (
                      <FilterItem
                        option={option}
                        selectedFilters={selectedFilters}
                        addingFilter={filter}
                        changeFilter={changeFilter}
                        addFilter={addFilter}
                        removeFilter={removeFilter}
                      />
                    )}
                  </Fragment>
                );
              })}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

const getSettingsFromStorage = ({
  tableName,
  settingsName,
}: {
  tableName: string;
  settingsName: "columnVisibility" | "columnOrder";
}) => {
  const allTableSettings = localStorage.getItem(localStorageKeys.TABLE_SETTING);
  if (allTableSettings) {
    const parsedTableSettings = JSON.parse(allTableSettings);
    const tableSettings = parsedTableSettings[tableName];
    if (tableSettings && tableSettings[settingsName]) {
      return tableSettings[settingsName];
    }
  }
};

const setSettingsInStorage = ({
  tableName,
  settings,
}: {
  tableName: string;
  settings:
    | {
        name: "columnVisibility";
        settings: VisibilityState;
      }
    | {
        name: "columnOrder";
        settings: ColumnOrderState;
      };
}) => {
  let allTableSettings: Record<
    string,
    { columnVisibility: VisibilityState; columnOrder: ColumnOrderState }
  > = {};
  const allTableSettingsString = localStorage.getItem(
    localStorageKeys.TABLE_SETTING,
  );
  if (allTableSettingsString) {
    allTableSettings = JSON.parse(allTableSettingsString);
  }
  allTableSettings[tableName] = {
    ...allTableSettings[tableName],
    [settings.name]: settings.settings,
  };
  localStorage.setItem(
    localStorageKeys.TABLE_SETTING,
    JSON.stringify(allTableSettings),
  );
};
