import { ColumnDef, Row } from "@tanstack/react-table";
import { ColDef } from "ag-grid-community/dist/types/main";
import axios from "axios";
import { format } from "date-fns";
import { Fragment, useState } from "react";
import { toast } from "sonner";

import { AsbestosSampleCell } from "@/components/AsbestosSampleCell";
import { AuditAssetDropdownMenu } from "@/components/AuditAssetsDropdownMenu";
import { AuditStatusBadge } from "@/components/AuditStatusBadge";
import { RiskBadge, RiskBadgeProps } from "@/components/RiskBadge";
import {
  Button,
  buttonVariants,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui";
import {
  AuditRow,
  AuditStatus,
  AuditStatusId,
  DocumentList,
  PaginatedResourceColumn,
} from "@/types";

import { getPath } from "./utils";

export const generateColumnDefs = <T extends object>(
  data: PaginatedResourceColumn[],
): ColumnDef<T>[] => {
  return data.map(({ header, accessorKey, cellInfo, sortable }) => {
    switch (cellInfo.type) {
      case "FILE_DOWNLOAD": {
        return {
          header,
          enableSorting: !!sortable,
          cell: ({ row }: { row: Row<T> }) => {
            const listItems = getPath(
              row.original,
              accessorKey,
            ) as DocumentList[];
            return (
              <div className="flex space-x-2">
                {listItems.map((item) => {
                  const key = `${item.type}-${item.url}`;
                  switch (item.type) {
                    case "CONVERT": {
                      const [conversionRequested, setConversionRequested] =
                        useState(false);
                      const convertDocument = async () => {
                        if (
                          conversionRequested ||
                          item.label !== "Convert to PDF"
                        ) {
                          return;
                        }
                        setConversionRequested(true);
                        await axios.post(item.url);

                        toast.success("Document queued for conversion");
                      };
                      return (
                        <Fragment key={key}>
                          <Button
                            variant="secondary"
                            onClick={convertDocument}
                            disabled={
                              conversionRequested ||
                              item.label !== "Convert to PDF"
                            }
                          >
                            {conversionRequested ? "Queued" : item.label}
                          </Button>
                        </Fragment>
                      );
                      break;
                    }
                    default: {
                      if (item.disabled === true) {
                        return (
                          <Tooltip key={key}>
                            <TooltipTrigger asChild>
                              <Button
                                disabled={true}
                                className={buttonVariants({
                                  variant: "secondary",
                                })}
                              >
                                {item.label}
                              </Button>
                            </TooltipTrigger>
                            <TooltipContent side="top" align="center">
                              {item.tooltip}
                            </TooltipContent>
                          </Tooltip>
                        );
                      }
                      return (
                        <Fragment key={key}>
                          <a
                            className={buttonVariants({ variant: "secondary" })}
                            href={item.url}
                            target="_blank"
                          >
                            {item.label}
                          </a>
                        </Fragment>
                      );
                    }
                  }
                })}
              </div>
            );
          },
          accessorFn: () => null,
        };
      }
      case "RISK_BADGE": {
        return {
          header,
          enableSorting: !!sortable,
          cell: ({ row }: { row: Row<T> }) => {
            const score = getPath(
              row.original,
              cellInfo.metadata.scoreAccessor,
            ) as number | null;
            const risk = getPath(
              row.original,
              cellInfo.metadata.riskAccessor,
            ) as string;
            const color = getPath(
              row.original,
              cellInfo.metadata.colorAccessor,
            ) as RiskBadgeProps["color"];

            const mode = getPath(
              row.original,
              cellInfo.metadata.modeAccessor,
            ) as RiskBadgeProps["mode"];

            const icon = getPath(
              row.original,
              cellInfo.metadata.iconAccessor,
            ) as string;

            if (score === null || score === undefined || !risk || !color) {
              if (score !== -1) {
                return "-";
              }
            }

            return (
              <RiskBadge
                color={color}
                label={risk}
                score={score}
                size={"sm"}
                mode={mode}
                icon={icon}
              />
            );
          },
          accessorFn: () => null,
        };
      }
      case "AUDIT_BADGE": {
        return {
          header,
          enableSorting: !!sortable,
          cell: ({ row }: { row: Row<T> }) => {
            const status = getPath(row.original, accessorKey) as AuditStatusId;
            return <AuditStatusBadge status={AuditStatus[status]} />;
          },
          accessorFn: () => null,
        };
      }
      case "DATE_TIME": {
        return {
          header,
          enableSorting: !!sortable,
          accessorFn: (row: T) => {
            const value = getPath(row, accessorKey);
            if (!value) {
              return "-";
            }

            return format(new Date(value), cellInfo.metadata.format);
          },
        };
      }
      case "AUDIT_ASSET_DROPDOWN_MENU": {
        return {
          header,
          enableSorting: !!sortable,
          cell: ({ row }: { row: Row<T> }) => {
            return <AuditAssetDropdownMenu audit={row.original as AuditRow} />;
          },
          accessorFn: () => null,
        };
      }
      case "ASBESTOS_SAMPLE_SHEET": {
        return {
          header,
          enableSorting: !!sortable,
          cell: ({ row }: { row: Row<T> }) => {
            const barcode = getPath(row.original, accessorKey);
            const sampleId = getPath(
              row.original,
              cellInfo.metadata.sampleIdAccessor,
            );
            const auditId = getPath(
              row.original,
              cellInfo.metadata.auditIdAccessor,
            );

            if (!barcode) {
              return "-";
            }

            return (
              <AsbestosSampleCell
                barcode={barcode}
                sampleId={sampleId}
                auditId={auditId}
              />
            );
          },
          accessorFn: () => null,
        };
      }
      default: {
        return {
          header,
          enableSorting: !!sortable,
          accessorFn: (row: T) => {
            if (!accessorKey.includes("|")) {
              return getPath(row, accessorKey) || "-";
            }
            const keys = accessorKey.split("|");
            const values = keys
              .map((key) => getPath(row, key))
              .filter((value) => value !== null && value !== undefined);
            return values.join(" ") || "-";
          },
        };
      }
    }
  });
};

export const generateAgColumnDefs = <T extends object>(
  data: PaginatedResourceColumn[],
  sorting: { order_by: string; order_direction: "asc" | "desc" },
  hiddenColumns: string[],
  isTableEditable: boolean,
): ColDef<T>[] => {
  return data.map(({ header, accessorKey, cellInfo, sortable, editable }) => {
    switch (cellInfo.type) {
      case "RISK_BADGE": {
        return {
          colId: header,
          headerName: String(header),
          sortable: !!sortable,
          hide: hiddenColumns.includes(header),
          sort: sorting.order_by === header ? sorting.order_direction : null,
          cellRenderer: ({ data }: { data: T | undefined }) => {
            const score = getPath(data, cellInfo.metadata.scoreAccessor) as
              | number
              | null;
            const risk = getPath(
              data,
              cellInfo.metadata.riskAccessor,
            ) as string;
            const color = getPath(
              data,
              cellInfo.metadata.colorAccessor,
            ) as RiskBadgeProps["color"];

            if (score === null || score === undefined || !risk || !color) {
              return "-";
            }

            return (
              <RiskBadge color={color} label={risk} score={score} size={"sm"} />
            );
          },
        };
      }
      default: {
        return {
          colId: accessorKey,
          headerName: String(header),
          hide: hiddenColumns.includes(header),
          sortable: !!sortable,
          sort: sorting.order_by === header ? sorting.order_direction : null,
          comparator: () => 0,
          valueGetter: ({ data }: { data: T | undefined }) => {
            if (!accessorKey.includes("|")) {
              return getPath(data, accessorKey) || "-";
            }
            const keys = accessorKey.split("|");
            const values = keys
              .map((key) => getPath(data, key))
              .filter((value) => value !== null && value !== undefined);
            return values.join(" ") || "-";
          },
          valueSetter: (params) => {
            // @ts-ignore
            params.data[accessorKey] = params.newValue;

            return true;
          },
          editable: isTableEditable && editable,
        };
      }
    }
  });
};
